.margin-bottom{
    margin-bottom: 1rem !important;
}

.margin-left{
    margin-left: 1rem !important;
}
.margin-right{
    margin-right: 1rem !important;
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/l/font?kit=ijwUs5PnXdA4ZZUA55d7&skey=8887f17a62203d44&v=v4) format('truetype');
}

.ag-popup {
    height: 0 !important;
    min-height: 0 !important;
}

.dropdown-item {
    color: black !important;
}
.align-right{
    text-align: right;
}

.onlyActiveWrapper{
    display: flex;
    align-items: flex-end;
}

.yellow-background{
    background-color: rgb(245, 255, 145) !important;
}

.hours-worked-div{
    display: flex;
    justify-content: space-between;
}

.hours-worked-date{
    width: 100%;
}

.linked-cell{
    text-decoration: underline;
    cursor:pointer;
    color:rgba(165 200 227 / 1) 
}

a.nav-link{
    font-size: 1rem !important;
    color: black !important;
}

a.nav-link:hover{
    color: rgb(48, 48, 48);
}

a.active{
    color: white !important;
}

html {
    font-size: 14px !important;
    position: relative;
    min-height: 100%;
    max-width: 100vw;
}

body {
    font-family: 'Avenir' !important;
    color: #333333;
    margin-bottom: 145px;
    background-image: url('../images/people-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.content {
    min-height: 90vh;
    padding: 0em 2em 2em !important;
    max-width: 100vw !important;
}

header .container {
    padding: .25em !important;
    max-width: 100vw !important;
}

.white-box {
    border-radius: 2rem;
    margin:4rem;
    padding:2rem;
    background: rgba(255 255 255 .75);
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .07);
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif !important;
    color: #052540;
}
h1 {
    font-size:3rem !important;
}

a {
    color: rgba(165 200 227 / 1) !important;
}

a:hover, a:active {
    color: rgba(165 200 227 / 1) !important;
}

.margin-top{
    margin-top: .5rem;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    margin: 1em 3em 1em 1em;
}

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.text-right{
    text-align: right;
}

.text-center{
    text-align: center;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
}


.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.loading{
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    animation: spin 2s linear infinite;
}

a.nav-link {
    font-size: 18px;
    padding: .5em 1em !important;
}

.login-btn, .or-btn {
    color: #ffffff !important;
    background: rgba(234 128 39 / 1) !important;
    border-radius: .25em;
    border: none;
}

.login-btn:hover, .or-btn:hover {
    color: #ea8027 !important;
    background: rgba(234 128 39 / 0.35) !important;
}

.lb-btn {
    color: #ffffff !important;
    background: rgba(165 200 227 / 1) !important;
    border-radius: .25em;
}

.lb-btn:hover {
    color: #ffffff !important;
    background: rgba(12 37 65 / 1) !important;
}
.gray-btn {
    color: #444444 !important;
    background: rgba(240 240 240 / 1) !important;
    border-radius: .25em;
    margin: 1em 0;
    padding: .5rem 2rem;
    font-size: 1.2rem;
}

.gray-btn:hover {
    color: #444444 !important;
    background: rgba(220 220 220 / 1) !important;
}
.blue-btn {
    color: #ffffff !important;
    background: rgba(12 37 65 / 1) !important;
    border-radius: .25em;
    margin:1em 0;
    padding:.5rem 2rem;
    font-size:1.2rem;
}
.blue-btn:hover {
    background: rgba(165 200 227 / 1) !important;
}

header a.nav-link {
    color: #052540 !important;
}

header a.nav-link:hover, a.nav-link:active {
    color: #a0c8e2 !important;
}

footer a.nav-link {
    color: #ffffff !important;
}

footer a.nav-link:hover, a.nav-link:active {
    color: #a0c8e2 !important;
}

.btn-group>.btn-check:checked+.btn{
    z-index: 0 !important;
}

.grid-center{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.full-width{
    width: 100%;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    background: #052540;
    color: #ffffff !important;
}

.all-branches-wrapper{
    width: 100%;
}

.or-separator{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.required{
    color: red !important;
}

.or-separator p{
    color: rgb(169, 169, 169) !important;
    margin: 0 .5rem 0 .5rem !important;
}

.or-separator div{
    width: 100% !important;
    height: 1px !important;
    background-color: rgb(194, 194, 194) !important;
}

.filter-wrapper{
    margin-top: .5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: row !important;
}

.filter-wrapper a{
    font-size: 12px !important;
}

.advanced-search{
    grid-row:1;
    grid-column: 1/3;
}

.filter-branches{
    grid-row:2;
    grid-column: 1;
}

.filter-messages{
    grid-row:3;
    grid-column: 1;
    margin: 0 !important;
}

.filter-menu{
    grid-row:4;
    grid-column: 1;
}

.download-button{
    flex: .1 0;
}

.absence-date{
    width: 100% !important;
}

.absence-grid-download{
    grid-row: 4;
    grid-column: 2;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.grid-download{
    grid-row: 4;
    grid-column: 2;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.filter-grid{
    grid-row:5;
    grid-column: 1/3;
}

.advanced-search-button{
    grid-row:3;
    grid-column:2;
}

.alert{
    margin: 0 !important;
    padding: 0.375rem 0.75rem !important;
}

.advanced-search{
    grid-column: 1 / span 3;
    grid-row: 1;
    /* display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr; */
}

.r1-c1{
    grid-row:1;
    grid-column:1;
}
.r1-c2{
    grid-row:1;
    grid-column:2;
}
.r1-c3{
    grid-row:1;
    grid-column:3;
}
.r2-c1{
    grid-row:2;
    grid-column:1;
}

.grid-select-buttons{
    display: flex;
    align-items: flex-end;
}

.grid {
    height: 80vh;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
    .filter-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr .5fr;
        gap: 10px;
    }
    .advanced-search{
        grid-column: 1 / span 3;
        grid-row: 1;
        /* display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr; */
    }

    .vh20{
        height: 30vh !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }



    .filter-branches{
        grid-row: 2;
        grid-column: 1;
    }

    .filter-messages{
        grid-row:2;
        grid-column: 2;
    }

    .hours-worked-date{
        width: 50vw;
    }

    .filter-menu{
        grid-row:3;
        grid-column: 1;
    }

    .grid-download{
        grid-row:2;
        grid-column: 3;
    }
    .advanced-search-button{
        grid-row:3;
        grid-column:3;
    }

    .filter-grid{
        grid-row:4;
        grid-column: 1/4;
    }

    .r1-c1{
        grid-row:1;
        grid-column:1;
    }
    .r1-c2{
        grid-row:1;
        grid-column:2;
    }
    .r1-c3{
        grid-row:1;
        grid-column:3;
    }
    .r2-c1{
        grid-row:2;
        grid-column:1;
    }

    .absence-date{
        width: 50% !important;
    }
}

.vh-60{
    height: 60vh !important;
}

.vh-40{
    height: 40vh !important;
}

.ag-theme-alpine {
        --ag-borders: none !important;
        --ag-header-background-color: #f0f0f08e;
        --ag-header-height: 40px;
        --ag-odd-row-background-color: #f0f0f08e;
        --ag-row-hover-color: #4585fca9;
        --ag-checkbox-unchecked-color: gray;
        --ag-checkbox-checked-color: blue;
        --ag-selected-row-background-color: rgba(0, 110, 255, 0.144);
        --ag-icon-font-family: agGridMaterial;
        --ag-row-height: 30px !important;
        width: 100% !important;
    }

    @media screen and (1600px > width ) {
        .ag-theme-alpine{
            height: 60vh;
        }
    }

    @media screen and (width >= 1600px) {
        .ag-theme-alpine{
            height: 70vh;
        }
    }

    .ag-theme-alpine ::-webkit-scrollbar {
        width: 0.95em;
        height: 0.95em;
      }
      
      .ag-theme-alpine ::-webkit-scrollbar-track {
        background-color: transparent;
      }
      
      .ag-theme-alpine ::-webkit-scrollbar-thumb {
        background-color:#000000a9;
        border-radius: 10px;
        padding: 2px;
        background-clip: padding-box;
        border:2px solid transparent;
      }