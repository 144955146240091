.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    background: #052540;
    color: #ffffff !important;
}

.footer-link{
    padding: 1rem;
}

.text-white{
    color: white;
}

.footer-link-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
}