a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
  }

.justify-content-end{
  align-content: flex-end;
}
  
  html {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }
  
  .box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  }

  .navbar-container{
      padding-left: 1rem;
      padding-right: 1rem;
  }

  .navbar-nav{
    width: 100%;
    justify-content: flex-end;
  }

  
  .full-name{
    padding: .5rem;
  }

  .pink{
    background-color: pink;
  }

  .red{
    background-color: red;
  }

  .nested-dropdown{
    list-style-type: none;
    padding-left: 10px;
  }

  .nested-dropdown-option{
    cursor: pointer;
  }

  .nested-dropdown-wrapper{
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  }

  .dropdown-option{
    font-weight: 500;
  }