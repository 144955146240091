.job-info-accordion .col{
    display: flex;
    align-items: center;
}

.job-info-accordion .col p:nth-child(even){
    padding-left: 1rem!important;
}

.sub-header{
    color: rgb(77, 77, 77);
}

.vh-40{
    height: 40vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.candidates-badge{
    margin-left: .5rem !important;
    padding: .5rem !important;
}