.chart{
    height: 100%;
    width: 100%;
}

.download-button{
    display: flex !important;
    align-items: flex-end !important;
    padding: 0 !important;
    margin: 0 !important;
    width: auto !important;
}

.bottom{
    display: flex !important;
    align-items: flex-end !important;
}

.custom-dropdown-department-option{
    cursor: pointer;
    padding: .5rem !important;
}

.hide{
    visibility: hidden !important;
    width: 0px !important;
    height: 0px !important;
}

.custom-dropdown-department-option:hover{
    background-color: #e5f1fb;
}

.graph-wrapper{
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    width: 98vw;
    height: 70vh;
}

.grid-1{
    grid-column: 1;
    grid-row:1;
    width: 30vw !important;
    padding: 0 !important;
}

.grid-2{
    grid-column: 2;
    grid-row:1;
    width: 30vw !important;
    padding: 0 !important;

}

.grid-3{
    grid-column: 3;
    grid-row:1;
    width: 30vw !important;
    padding: 0 !important;

}

/* .summary-graph{
    width: 90vw;
    height: 25vh;
} */