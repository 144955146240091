.green-highlight{
    background-color: #b3d9a2;
}

.red-highlight{
    background-color: #f6a680;
}

.sunburst-grid{
    height: 40vh;
    width: 100%;
}

.sunburst-legend{
    width: 100%;
    height: 40vh;
}

.overview-download-button-wrapper{
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end !important;
}