.candidate-status-wrapper{
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    width: 50vw !important;
}

.contact-buttons-wrapper{
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    width: 100% !important;
    margin-top: .5rem !important;
}

.contact-buttons-wrapper > .input-group{
    width: 90% !important;
}

.contact-buttons-wrapper > button{
    width: 20% !important;
    margin-left: .5rem !important;
}


.candidate-status-label{
    width: auto !important;
    margin: 0 !important;
    padding-right: .5rem !important;
}

.candidate-status-dropdown{
    width: 100% !important;
}

.status-tooltip-wrapper{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-left: .25rem !important;
}
