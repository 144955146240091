.card{
    width:100%;
}

.card-title{
    text-align: center;
}

.padding{
    padding:1rem;
}

form.margin-top > label{
    margin-top:1rem;
}

.login-button{
    width: 100%;
    margin-top:1rem;
}

form span{
    color:red;
}

.login-alert{
    margin-top:1rem;
}