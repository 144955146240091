.buttons-wrapper{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.switch-label-wrapper > .form-check-label{
    width: 100% !important;
}

.status-tooltip-wrapper{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-left: .25rem !important;
}

.switch-label-wrapper > .form-check-label > div{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
}
.switch-label-wrapper > .form-check-label > div > span{
    padding-right: .5rem !important;
}

.pill-wrapper{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.nav-pills .nav-link.active{
    background-color: rgb(188 223 251) !important;
    color: black !important;
}

.nav-pills .nav-link{
    border: 1px solid rgb(192, 192, 192) !important;
}

.highlight-row{
    background-color:#f6a680 !important;
}

.refuse-button{
    background-color:#ff8c29 !important;
    border-color: #ff8c29 !important;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.refuse-button:hover{
    background-color:#fa8117 !important;
}

.filter-group-wrapper{
    margin-top: .5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: row !important;
    width: 100% !important;
}

.filter-group-wrapper a{
    font-size: 12px !important;
}

.filter-group-new-order-button{
    justify-self: flex-end !important;
}