.green-cell-background{
    background-color: #b3d9a2;
}

.red-cell-background{
    background-color: #f6a680;
}

.department-dropdown-wrapper{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}